import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'portal', requiresAuth: true },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: { layout: 'portal', requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/registeren',
    name: 'Register',
    meta: { layout: 'empty', requiresAuth: false },
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/inloggen',
    name: 'Login',
    meta: { layout: 'empty', requiresAuth: false },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/herstellen',
    name: 'PasswordRecovery',
    meta: { layout: 'empty', requiresAuth: false },
    component: () => import(/* webpackChunkName: "passwordrecovery" */ '../views/PasswordRecovery.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    meta: { layout: 'empty', requiresAuth: false },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next('/inloggen');
  }

  next();
})

export default router
