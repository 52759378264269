<template>
  <component :is="layout">
      <router-view/>
  </component>
</template>

<style>

</style>

<script>
  const default_layout = 'portal';

    export default {
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout';
            }
        }
    }
</script>
